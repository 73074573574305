import Navigation from '@/app/fuse-layouts/shared-components/Navigation';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import NavPwaBanner from './NavPwaBanner';
import ViewAs from './ViewAs';
import NavbarTime from '@shared/components/Navbar/NavbarTime';

const NavbarLayout1 = ({ className }) => {
	const user = useSelector(({ auth }) => auth.user);

	return (
		<div
			className={clsx('flex flex-col h-full min-h-full lg:min-h-auto overflow-auto mb-64 ', className)}
			style={{
				backgroundImage: 'url("assets/images/logos/nav-bar.png")',
				backgroundColor: '#346CE8',
				backgroundSize: 'cover',
			}}
		>
			<NavbarTime />
			{!user.role.includes('super_admin') ? (
				<div className="mt-[32px] text-pd-base ml-[34px] leading-[22.4px]">
					<p className="text-white text-transform max-w-[169px]">{user?.school?.name || user.data?.school?.name}</p>
				</div>
			) : null}
			<Navigation className="pb-[20px]" layout="vertical" />

			{user.role.includes('super_admin') || user.role.includes('super_school_admin') || user.role.includes('product_owner') ? (
				<div className="mb-24 pb-16">
					<ViewAs />
				</div>
			) : null}
			<NavPwaBanner />
		</div>
	);
};

export default NavbarLayout1;
