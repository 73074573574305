import React from 'react';
import { Avatar, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import dayjs from 'dayjs';

export const CheckinCodeNotification = ({ notification, handleNotificationRead, userTimeZone, key }) => {
	const { data } = notification;

	return (
		<Table key={key}>
			<TableBody>
				<TableRow>
					<TableCell
						className="cursor-pointer notification-wrapper"
						onClick={() => handleNotificationRead(notification)}
						style={{
							backgroundColor: `${notification.read_at ? '' : '#F0F9FE'}`,
						}}
					>
						<div className="flex" style={{ gap: '5px' }}>
							<div className="">
								<Avatar
									src={
										data?.data?.type === 'checkin_code_request'
											? '/assets/images/checkin-code-request-icon.png'
											: notification?.room?.thumb
									}
									style={{ width: '35px', height: '35px' }}
								/>
							</div>
							<div className="flex flex-col">
								<span className="font-bold" style={{ fontSize: '14px', marginBottom: '4px' }}>
									{data?.title}
								</span>
								<span style={{ fontSize: '12px' }}>
									<span className="font-bold">{data?.body.split(' ').slice(0, 2).join(' ')}</span>{' '}
									<span>{data?.body.split(' ').slice(2).join(' ')}</span>
								</span>
							</div>
							<div className="flex flex-col items-end justify-between" style={{ flexShrink: 0 }}>
								<span style={{ fontSize: '11px', marginTop: '4px' }}>
									{dayjs.utc(notification.created_at).tz(userTimeZone).format('MMMM D, YYYY, hh:mm A')}
								</span>
								<span className="mt-6 flex" style={{ gap: '8px' }}>
									<AvatarGroup className="avatar-default-text" max={2}>
										{Object.values(data?.data?.childs).map((child, k) => {
											return <Avatar key={k} src={child} />;
										})}
									</AvatarGroup>
								</span>
							</div>
						</div>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};
