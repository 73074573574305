import { amber, blue } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as Actions from '@/app/store/actions';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Close, InfoOutlined } from '@material-ui/icons';
import SuccessCircleCheckIcon from '@shared/icons/SuccessCircleCheck';
import ErrorCircleXIcon from '@shared/icons/ErrorCircleXIcon';
import WarningCircleIcon from '@shared/icons/WarningCircleIcon';

const useStyles = makeStyles(() => ({
	root: {},
	success: {
		backgroundColor: '#E6F9EE',
		color: '#000000',
		borderRadius: '1.4rem',
		border: '1px solid #BBD2C4',
		display: 'block',
	},
	error: {
		backgroundColor: '#FEF1F1',
		borderRadius: '1.4rem',
		border: '1px solid #CCB7B7',
		color: '#000000',
		display: 'block',
	},
	// Note: info isn't being used in the app currently but will need to be updated to match the new design if needed
	info: {
		backgroundColor: blue[600],
		color: '#FFFFFF',
		display: 'block',
	},
	warning: {
		backgroundColor: '#FEF7F1',
		border: '1px solid #D5CABF',
		color: '#000000',
		display: 'block',
	},
}));

const variantData = {
	success: {
		icon: <SuccessCircleCheckIcon className="w-32 h-32 sm:w-48 sm:h-48" />,
	},
	warning: { icon: <WarningCircleIcon className="w-32 h-32 sm:w-48 sm:h-48" />, color: amber[600] },
	error: { icon: <ErrorCircleXIcon className="w-32 h-32 sm:w-48 sm:h-48" />, color: '#FEF1F1' },
	info: { icon: <InfoOutlined />, color: blue[600] },
};

const FuseMessage = () => {
	const dispatch = useDispatch();
	const state = useSelector(({ fuse }) => fuse.message.state);
	const options = useSelector(({ fuse }) => fuse.message.options);

	const classes = useStyles();

	return (
		<Snackbar {...options} onClose={() => dispatch(Actions.hideMessage())} open={state}>
			<SnackbarContent
				className={clsx(classes[options.variant])}
				elevation={15}
				message={
					<div className="flex  items-center w-full justify-between gap-32">
						<div className="flex items-center gap-16">
							{variantData[options.variant]?.icon}
							<div>
								<p className="font-bold text-lg">{options.title}</p>
								<Typography>{options.message}</Typography>
							</div>
						</div>
						<button
							aria-label="Close"
							className="bg-white rounded-8 p-4 border border-black/25 text-black/75"
							key="close"
							onClick={() => dispatch(Actions.hideMessage())}
							style={{
								boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.04)',
								border: '1px solid rgba(0, 0, 0, 0.02)',
								color: 'rgba(82, 82, 82, 1)',
							}}
							type="button"
						>
							<Close />
						</button>
					</div>
				}
			/>
		</Snackbar>
	);
};

export default React.memo(FuseMessage);
