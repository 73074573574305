import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import NavbarMobileToggleButton from '@/app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import UserMenu from '@/app/fuse-layouts/shared-components/UserMenu';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SchoolAdminTopNav from './schoolAdminTopNav/SchoolAdminTopNav';
import Topbar from './schoolAdminTopNav/Topbar';
import SuperAdminTopNav from './superAdminTopNav/superAdminTopNav';
import history from '@/@history';
import { stopBeamsClient } from '@/utils/utils';
import MaydayMessageProvider from '@/app/context/mayday-messages/MaydayMessageProvider';
import { Divider } from '@material-ui/core';
import ResetLoginCodeModal from './schoolAdminTopNav/ResetLoginCodeModal';

const ToolbarLayout1 = () => {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);
	const [unreadCount, setUnreadCount] = useState(0);
	const user = useSelector(({ auth }) => auth.user);

	useEffect(() => {
		if (user?.data?.id && window.pendo) {
			// prefix the id with the current environment to enable results filtering on the pendo portal
			let currentEnv = '';
			if (import.meta.env.VITE_ENV === 'development') {
				currentEnv = 'dev-';
			} else if (import.meta.env.VITE_ENV === 'stage') {
				currentEnv = 'stage-';
			}
			window.pendo.initialize({
				visitor: {
					id: `${currentEnv}${user.data.email}`,
					userId: user.data.id,
					name: `${user.data.first_name} ${user.data.last_name}`,
					email: user.data.email,
					role: user.data.role,
				},
				account: {
					id: `${currentEnv}${user.data.school.id}-${user.data.school.name}`,
					name: user.data.school.name,
				},
			});
		}
	}, [user]);

	let beamsClient;
	let id;
	if (user.role[0] === 'super_admin' || user.role[0] === 'school_admin' || user.role[0] === 'sub_admin') {
		id = user.data.id;
	} else {
		id = user?.school?.admins ? user.school.admins[0].id : user?.school?.pivot?.user_id;
	}

	const startBeamsClient = () => {
		window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
			beamsClient = new PusherPushNotifications.Client({
				instanceId: import.meta.env.VITE_BEAMS_INSTANCE_ID,
				serviceWorkerRegistration,
			});
			beamsClient
				.getRegistrationState()
				.then((state) => {
					const states = PusherPushNotifications.RegistrationState;
					switch (state) {
						case states.PERMISSION_DENIED: {
							// Notifications are blocked
							// Show message saying user should unblock notifications in their browser
							break;
						}
						case states.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS: {
							beamsClient
								.addDeviceInterest(`App.Models.User.${id}`)
								.then(() => beamsClient.getDeviceInterests())
								.catch(console.error);
							break;
						}
						case states.PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS:
						case states.PERMISSION_PROMPT_REQUIRED: {
							beamsClient
								.start()
								.then((client) => client.getDeviceId())
								.then(() => beamsClient.addDeviceInterest(`App.Models.User.${id}`))
								.then(() => beamsClient.getDeviceInterests())
								.catch(console.error);
							break;
						}
						default: {
							throw new Error(`Unexpected Pusher SDK state: ${state}`);
						}
					}
				})
				.catch((e) => console.error('Could not get registration state', e));
		});
	};
	useEffect(() => {
		if (user.role.length > 0) {
			startBeamsClient();
		}

		return () => {
			stopBeamsClient(beamsClient);
		};
	}, [user]);

	return (
		<ThemeProvider theme={toolbarTheme}>
			<MaydayMessageProvider>
				<ResetLoginCodeModal setUnreadCount={setUnreadCount} />
				<AppBar
					className="flex z-10"
					color="default"
					id="fuse-toolbar"
					position="sticky"
					style={{ backgroundColor: '#fff', height: 81 }}
				>
					<Toolbar className="p-0 flex items-center h-full">
						{config.navbar.display && config.navbar.position === 'left' ? (
							<Hidden lgUp>
								<NavbarMobileToggleButton className="w-32 h-32 sm:w-48 sm:h-48 p-0 mx-10" />
							</Hidden>
						) : null}

						<button
							className="items-center lg:px-36"
							onClick={() => {
								if (user.role?.length) {
									if (user.role[0] === 'super_admin') {
										history.push('/company');
									} else {
										history.push('/home');
									}
								}
							}}
							type="button"
						>
							<img
								alt=""
								className={`${user?.role?.length && 'cursor-pointer'} max-w-160 block px-16`}
								src="assets/images/logos/logo.png"
							/>
						</button>
						<Divider className=" my-16 hidden lg:block" flexItem orientation="vertical" />
						<div className="flex flex-1">
							<div className="hidden lg:block px-10 flex-1 min-w-0">
								{['school_admin', 'super_school_admin', 'sub_admin'].includes(user.role[0]) ? (
									<Topbar key={user} setUnreadCount={setUnreadCount} unreadCount={unreadCount} />
								) : null}
							</div>
						</div>
						<div className="flex justify-between items-center">
							{['super_admin', 'product_owner'].includes(user.role[0]) ? (
								<SuperAdminTopNav />
							) : user.role.length ? (
								<SchoolAdminTopNav setUnreadCount={setUnreadCount} unreadCount={unreadCount} />
							) : (
								''
							)}
							{user.role[0] !== 'super_admin' ? (
								<Divider className="mx-16  md:ml-0 sm:mr-16 my-16" flexItem orientation="vertical" />
							) : null}
							{user.role.length ? <UserMenu /> : ''}
						</div>
					</Toolbar>
				</AppBar>
			</MaydayMessageProvider>
		</ThemeProvider>
	);
};

export default ToolbarLayout1;
