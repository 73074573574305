import axios from '@shared/axios-shared';

export const getStaff = (search, page) => {
	return axios.get(`/api/v1/school/staff?q=${search}&page=${page}`);
};

export const getAllStaff = () => {
	// Note: the 'all' at the end of this endpoint can be anything. Gets all staff associated with the school of the authenticated user
	return axios.get(`/api/v1/school/staff/all`);
};

export const getStaffById = (id) => {
	return axios.get(`/api/v1/school/staff/detail/${id}`);
};

export const addStaff = (data) => {
	return axios.post('/api/v1/school/staff', data);
};

export const updateStaff = (data, id) => {
	return axios.put(`/api/v1/school/staff/${id}`, data);
};

export const deleteStaffDocument = (staffId, documentId) => {
	return axios.delete(`/api/v2/admin/staff/${staffId}/document/${documentId}`);
};

export const generateStaffCode = (staffId) => {
	return axios.post(`/api/v2/admin/staff/${staffId}/code`);
};

export const getCurrentStaffCode = (staffId) => {
	return axios.get(`/api/v2/admin/staff/${staffId}/code`);
};
