import authRoles from '../auth/authRoles';

/** The `navigationConfig` constant is an array of objects that represents the navigation menu.
 *  Each object in the array represents a menu item with properties such as `id`, `title`, `translate`, `type`, `isCustomIcon`, `icon`, `url`, and `auth`:
 * - `auth`: defines who can see the menu item
 * - `url`: defines the URL of the menu item
 */
const navigationConfig = [
	{
		id: 'Home',
		title: 'Home',
		translate: 'Home',
		type: 'item',
		isCustomIcon: true,
		icon: 'home.png',
		url: '/company',
		auth: authRoles.admin,
		permission: 'Company Dashboard',
	},
	{
		id: 'schools',
		title: 'schools',
		translate: 'schools',
		type: 'item',
		isCustomIcon: true,
		icon: 'school-admin.png',
		url: '/schools',
		auth: authRoles.admin,
		permission: 'Schools',
	},
	{
		id: 'reports',
		title: 'CompanyReports',
		translate: 'reports',
		type: 'item',
		isCustomIcon: true,
		icon: 'report.png',
		url: '/company-reports',
		auth: authRoles.admin,
		permission: 'Company Reports',
	},
	{
		id: 'usermanagement',
		title: 'User Management',
		translate: 'User Management',
		type: 'item',
		isCustomIcon: true,
		icon: 'usermanagement2.png',
		url: '/user-management',
		auth: authRoles.admin,
		permission: 'User Management',
	},
	{
		id: 'home',
		title: 'Home',
		translate: 'Home',
		type: 'item',
		isCustomIcon: true,
		icon: 'home.png',
		url: '/home',
		auth: authRoles.subadmin,
		permission: 'Dashboard',
		alwaysShow: true,
	},
	{
		id: 'calendar',
		title: 'Calendar',
		translate: 'Calendar',
		type: 'item',
		isCustomIcon: true,
		icon: 'calendar.png',
		url: '/calendar',
		auth: authRoles.subadmin,
		permission: 'Calendar',
	},
	{
		id: 'students',
		title: 'students',
		translate: 'students',
		type: 'item',
		isCustomIcon: true,
		icon: 'students.png',
		url: '/students',
		auth: authRoles.subadmin,
		permission: 'Students',
	},
	{
		id: 'staff',
		title: 'staff',
		translate: 'staff',
		type: 'item',
		isCustomIcon: true,
		icon: 'staff.png',
		url: '/staff',
		auth: authRoles.subadmin,
		permission: 'Staff',
		hiddenOnMobile: true, // TODO: Enable this when the PWA version is ready
	},
	{
		id: 'rooms',
		title: 'rooms',
		translate: 'rooms',
		type: 'item',
		isCustomIcon: true,
		icon: 'rooms.png',
		url: '/rooms',
		auth: authRoles.subadmin,
		permission: 'Rooms',
		hiddenOnMobile: true, // TODO: Enable this when the PWA version is ready
	},
	{
		id: 'staffschedule',
		title: 'staff schedule',
		translate: 'staff schedule',
		type: 'item',
		isCustomIcon: true,
		icon: 'staff-schedules.png',
		url: '/staff-schedule',
		auth: authRoles.subadmin,
		permission: 'staff-schedule',
		hiddenOnMobile: true, // TODO: Enable this when the PWA version is ready
	},
	{
		id: 'messaging',
		title: 'messaging',
		translate: 'Messaging',
		type: 'item',
		isCustomIcon: true,
		icon: 'message.png',
		url: '/messaging',
		auth: authRoles.subadmin,
		permission: 'Messaging',
	},
	{
		id: 'LiveStreaming',
		title: 'Live Streaming',
		translate: 'Live Streaming',
		type: 'item',
		isCustomIcon: true,
		icon: 'live-streaming.png',
		url: '/livestreaming',
		auth: authRoles.subadmin,
		permission: 'Live_Streaming',
	},
	{
		id: 'schooladmin',
		title: 'School Admins',
		translate: 'School Admins',
		type: 'item',
		isCustomIcon: true,
		icon: 'school-admin.png',
		url: '/subadmin',
		auth: authRoles.subadmin,
		permission: 'Sub_Admin',
		hiddenOnMobile: true, // TODO: Enable this when the PWA version is ready
	},
	{
		id: 'reports-subadmin',
		title: 'reports',
		translate: 'Reports',
		type: 'item',
		isCustomIcon: true,
		icon: 'report.png',
		url: '/reports',
		auth: authRoles.subadmin,
		permission: 'Reports',
		hiddenOnMobile: true, // TODO: Enable this when the PWA version is ready
	},

	{
		id: 'settings',
		title: 'settings',
		translate: 'settings',
		type: 'item',
		isCustomIcon: true,
		icon: 'settings.png',
		url: '/settings',
		auth: authRoles.subadmin,
		permission: 'Settings',
		hiddenOnMobile: true, // TODO: Enable this when the PWA version is ready
	},
];

export default navigationConfig;
