import * as React from "react";

const RegenerateIcon = (props) => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 21 20"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.642 18.114C5.871 17.45 3 14.07 3 10 3 5.932 5.87 2.552 9.642 1.89M9.643 1.889l-.82 1.498M9.642 1.888 8.211 1M12.357 1.887C16.13 2.55 19 5.93 19 9.999c0 4.07-2.871 7.45-6.643 8.113M12.357 18.111l.82-1.498M12.357 18.111 13.79 19"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
    />
    <path
      d="M13.69 14.37H8.31c-.526 0-.953-.438-.953-.98V9.851c0-.541.427-.98.953-.98h5.38c.526 0 .952.439.952.98v3.54c0 .54-.426.98-.952.98ZM13.796 8.87v-.887c0-1.588-1.252-2.876-2.796-2.876-1.543 0-2.795 1.288-2.795 2.876v.887M11 11.232v.776"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
    />
  </svg>
);

export default RegenerateIcon;
