import React from 'react';

const ProductOwnerConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
					display: false,
                },
                leftSidePanel: {display: false},
                rightSidePanel: {display: false},
                announcement: {display: false},
            },
        },
    },
    routes: [
        {
            path: '/product-owner/login',
            component: React.lazy(() => import('./ProductOwnerLogin.jsx')),
        },
    ],
};

export default ProductOwnerConfig;
