import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import FuseNavItem, { registerComponent } from './FuseNavItem';
import FuseNavVerticalCollapse from './vertical/FuseNavVerticalCollapse';
import FuseNavVerticalGroup from './vertical/FuseNavVerticalGroup';
import FuseNavVerticalItem from './vertical/FuseNavVerticalItem';

/*
Register Fuse Navigation Components
 */
registerComponent('vertical-group', FuseNavVerticalGroup);
registerComponent('vertical-collapse', FuseNavVerticalCollapse);
registerComponent('vertical-item', FuseNavVerticalItem);
registerComponent('vertical-divider', () => <Divider className="my-16" />);
registerComponent('horizontal-divider', () => <Divider className="my-16" />);

const useStyles = makeStyles((theme) => ({
	navigation: {
		'& .list-item': {
			'&:hover': {
				backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.04)',
			},
			'&:focus:not(.active)': {
				backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0,0,0,.05)',
			},
		},
	},
	verticalNavigation: {
		'&.active-square-list': {
			'& .list-item, & .active.list-item': {
				width: '100%',
				borderRadius: '0',
			},
		},
		'&.dense': {
			'& .list-item': {
				paddingTop: 0,
				paddingBottom: 0,
				height: 32,
			},
		},
	},
	'@global': {
		'.popper-navigation-list': {
			'& .list-item': {
				padding: '8px 12px 8px 12px',
				height: 20,
				minHeight: 20,
				'& .list-item-text': {
					padding: '0 0 0 8px',
				},
			},
			'&.dense': {
				'& .list-item': {
					minHeight: 32,
					height: 32,
					'& .list-item-text': {
						padding: '0 0 0 8px',
					},
				},
			},
		},
	},
}));

const FuseNavigation = (props) => {
	const classes = useStyles(props);
	const { navigation, active, dense, className } = props;

	return navigation.length > 0 ? (
		<List
			className={clsx(
				'navigation whitespace-nowrap',
				classes.navigation,
				classes.verticalNavigation,
				`active-${active}-list`,
				dense && 'dense',
				className
			)}
		>
			{navigation.map((_item) => {
				// TODO: Remove this wrapper when all PWA updates have been made to menu items
				return (
					<div className={`${_item.hiddenOnMobile ? 'hidden md:block' : ''}`} key={_item.id}>
						<FuseNavItem item={_item} nestedLevel={0} type={`vertical-${_item.type}`} />
					</div>
				);
			})}
		</List>
	) : null;
};

FuseNavigation.propTypes = {
	navigation: PropTypes.array.isRequired,
};

FuseNavigation.defaultProps = {
	layout: 'vertical',
};

export default React.memo(FuseNavigation);
