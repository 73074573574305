import React from 'react';
import { Avatar, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import dayjs from 'dayjs';

export const TeacherAnnouncementNotification = ({ notification, handleAcknowledgeMaydayMessage, userTimeZone }) => {
	const { data } = notification;

	return (
		<Table key={notification.auto_id}>
			<TableBody>
				<TableRow>
					<TableCell
						className="notification-wrapper"
						style={{
							backgroundColor: `${notification.deleted_at ? '' : '#F0F9FE'}`,
							width: '450px',
						}}
					>
						<div className="flex" style={{ gap: '5px' }}>
							<div className="">
								<Avatar
									src={data?.data?.room?.thumb || 'assets/images/schoolAdminTopNav/noti-icon.svg'}
									style={{ width: '35px', height: '35px' }}
								/>
							</div>
							<div className="flex flex-col" style={{ flexGrow: 1 }}>
								<span className="font-bold" style={{ fontSize: '14px', marginBottom: '4px' }}>
									{data?.data?.room?.name}
								</span>
								<span style={{ fontSize: '12px' }}>
									<span className="font-bold">{data?.body.split(' ').slice(0, 1).join(' ')}</span>{' '}
									<span>{data?.body.split(' ').slice(1).join(' ')}</span>
								</span>
							</div>
							<div className="flex flex-col items-end gap-10" style={{ flexShrink: 0 }}>
								<span style={{ fontSize: '11px', marginTop: '4px' }}>
									{dayjs.utc(notification.created_at).tz(userTimeZone).format('MMMM D, YYYY, hh:mm A')}
								</span>
								{notification.deleted_at === null ? (
									<CustomButton onClick={() => handleAcknowledgeMaydayMessage(notification)}>OK</CustomButton>
								) : null}
							</div>
						</div>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};
