import axios from '@shared/axios-shared';
import { mergeUserParams } from '@shared/services/schools.js';

const getCompanySchools = (search = null, from = null, to = null, page = 1, sort = null, isSchoolAdmin = false) => {
	let config = mergeUserParams({ page, search, from, to, sort });
	return axios.get(`/api/v1/${isSchoolAdmin ? 'admin' : 'company'}/school`, config);
};

const getMySchools = (search, page = 1, company_id = null) => {
	let config = mergeUserParams({ page, name: search, active: true, company_id }, true);

	return axios.get('/api/v2/schools/list', config);
};

const getCompanySchool = (id) => {
	return axios.get(`/api/v1/company/school/${id}`);
};

const createSchool = (data) => {
	return axios.post(`/api/v1/company/school`, data);
};

const updateSchool = (data, id) => {
	return axios.put(`/api/v1/company/school/${id}`, data);
};

const updateSchoolStatus = (id, data) => {
	return axios.post(`/api/v1/company/school/status/${id}`, data);
};

const getCameraServices = () => {
	return axios.get(`api/v1/company/camera-services`);
};

const getStateListByCountry = (country_id) => {
	return axios.get(`api/v1/states/${country_id}`);
};

const getCountryList = () => {
	return axios.get(`api/v1/countries?code=USA`);
};

const getCityList = (state_id) => {
	return axios.get(`/api/v1/cities?state_id=${state_id}`);
};

const getSearchableStateList = (search, page) => {
	return axios.get(`api/v1/countries/USA/states?search=${search}&page=${page}`);
};

const getSearchableCityList = (state_id, search, page) => {
	return axios.get(`api/v1/states/${state_id}/cities?search=${search}&page=${page}`);
};

export {
	getCompanySchool,
	getCompanySchools,
	getMySchools,
	createSchool,
	updateSchool,
	updateSchoolStatus,
	getCameraServices,
	getCountryList,
	getStateListByCountry,
	getCityList,
	getSearchableStateList,
	getSearchableCityList,
};
