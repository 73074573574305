import React from 'react';
import { Avatar, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import dayjs from 'dayjs';

export const StaffAttendanceNotification = ({ notification, handleNotificationRead, userTimeZone, key }) => {
	const { data } = notification;

	return (
		<Table key={key}>
			<TableBody>
				<TableRow>
					<TableCell
						className="cursor-pointer notification-wrapper"
						onClick={() => handleNotificationRead(notification)}
						style={{
							backgroundColor: `${notification.read_at ? '' : '#F0F9FE'}`,
							width: '450px',
						}}
					>
						<div className="flex" style={{ gap: '5px' }}>
							<div className="">
								<Avatar
									src={data?.room?.thumb || 'assets/images/schoolAdminTopNav/noti-icon.svg'}
									style={{ width: '35px', height: '35px' }}
								/>
							</div>
							<div className="flex flex-col" style={{ flexGrow: 1 }}>
								<span className="font-bold" style={{ fontSize: '14px', marginBottom: '4px' }}>
									{data?.room?.name}
								</span>
								<span style={{ fontSize: '12px' }}>
									<span className="font-bold">{data?.message.split(' ').slice(0, 2).join(' ')}</span>{' '}
									<span>
										{`${data?.message.split(' ').slice(2).join(' ')} ${dayjs
											.utc(notification.created_at)
											.tz(userTimeZone)
											.format('MMMM D, YYYY, hh:mm A')}`}
									</span>
								</span>
							</div>
							<div className="flex flex-col items-end" style={{ flexShrink: 0 }}>
								<span style={{ fontSize: '11px', marginTop: '4px' }}>
									{dayjs.utc(notification.created_at).tz(userTimeZone).format('MMMM D, YYYY, hh:mm A')}
								</span>
								<span>
									<Avatar src={data?.staff_image} />
								</span>
							</div>
						</div>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};
