import secureLocalStorage from "react-secure-storage";

/**
 * Merges currently selected company and school to the request params.
 * @param {object} params The current request params.
 * @param {boolean} use_base_token If true, use the superadmin auth token.
 * @returns {{params: {object}}} The request config ready for axios.
 */
const mergeUserParams = (params = {}, use_base_token = false) => {
  let config = Object.assign({ params: {} }, { params });
  const user = JSON.parse(secureLocalStorage.getItem("user"));

  let company_id = user.data.selected_company ?? null;
  if (company_id && !Object.hasOwn(config.params, "company_id")) {
    config.params.company_id = company_id;
  }
  let school_id = user.data.selected_school ?? null;
  if (school_id && !Object.hasOwn(config.params, "school_id")) {
    config.params.school_id = school_id;
  }

  if (use_base_token && user.role.includes("super_school_admin")) {
    config.headers = {
      Authorization: `Bearer ${secureLocalStorage.getItem("superadmin_token")}`,
    };
  }

  return config;
};

export { mergeUserParams };
