/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { TableCell, TableRow, CircularProgress, Avatar, Table, TableBody } from '@material-ui/core';
import {
	deleteAllNotification,
	readNotification,
	acknowledgeMaydayMessage,
	getUnreadNotifications,
} from '@/app/services/notifications/notifications';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import './notifications.css';
import { useSelector, useDispatch } from 'react-redux';
import History from '@/@history';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as Actions from '@/app/store/actions';
import { MaydayMessageContext } from '@/app/context/mayday-messages/MaydayMessageContext';
import NotificationsIcon from '@/icons/TopNav/NotificationIcon';
import TopNavButton from '@/app/customComponents/TopNavButton';
import timezone from 'dayjs/plugin/timezone';
import { StaffAttendanceNotification } from './NotificationTypes/StaffAttendanceNotification';
import { TeacherAnnouncementNotification } from './NotificationTypes/TeacherAnnouncementNotification';
import { DefaultNotification } from './NotificationTypes/DefaultNotification';
import { CheckinCodeNotification } from './NotificationTypes/CheckinCodeRequestNotification';
dayjs.extend(utc);
dayjs.extend(timezone);

const Notifications = ({
	notifications,
	loadingNotifications,
	handleLoadMore,
	hasMore,
	setUnreadCount,
	unreadCount,
	setNotifications,
	unreadMessagesCount,
	setUnreadMessagesCount,
	fetchingMore,
}) => {
	const {
		maydayMessageData,
		setMaydayMessageData,
		setMaydayBarVisibility,
		setMaydayMessageIsReading,
		setResetLoginCodeModalOpen,
		setResetLoginCodeModalData,
	} = useContext(MaydayMessageContext);
	const dispatch = useDispatch();
	const [userMenu, setUserMenu] = useState(null);
	const user = useSelector(({ auth }) => auth.user);

	const userMenuClick = (event) => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	const userTimeZone = dayjs.tz.guess();

	const handleNotificationRead = (notification) => {
		const time = new Date().getTime();
		const temp = notifications.map((n) => (n.id === notification.id ? { ...n, read_at: time } : n));
		if (!notification.read_at) {
			setNotifications(temp);
			setUnreadCount(unreadCount - 1);
			readNotification(notification.id);
		}
		if (notification?.data?.data?.type === 'checkin_code_request') {
			const id = Object.keys(notification.data.data.childs)[0];
			History.push({ pathname: `/students-student/${id}` });
		}
	};

	const readAllNotification = () => {
		deleteAllNotification()
			.then(() => {
				setNotifications(notifications.filter((n) => n.data.type === 'teacher_announcement'));
				// Fetch the updated unread notifications count
				getUnreadNotifications()
					.then((res) => {
						setUnreadCount(res.data.count);
						setUnreadMessagesCount(res.data.school_conversation_count);
					})
					.catch((err) => {
						console.log(err);
					});
			})
			.catch((resp) => {
				dispatch(
					Actions.showMessage({
						message: resp.data.message,
						autoHideDuration: 1500,
						variant: 'success',
					})
				);
			});
	};

	const handleAcknowledgeMaydayMessage = (notification) => {
		if (notification?.data?.title === 'Staff Code Reset Request') {
			setResetLoginCodeModalOpen(true);
			setResetLoginCodeModalData(notification);
			return;
		}
		setMaydayMessageIsReading(true);
		acknowledgeMaydayMessage(notification.auto_id)
			.then((res) => {
				if (res.status === 200) {
					setUnreadCount(unreadCount - 1);
					const notifications = maydayMessageData.notifications.filter((n) => n.id !== notification.id);
					if (notifications.length === 0) {
						setMaydayBarVisibility(false);
					}
					setMaydayMessageData((prevData) => ({
						...prevData,
						notifications: notifications,
						activeIndex: prevData.activeIndex % (notifications.length ? notifications.length : 1),
					}));
				}
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: err?.response?.data?.message || 'Failed to mark notification as read',
						variant: 'error',
					})
				);
				console.log(err?.response?.data || err?.response);
			})
			.finally(() => {
				setMaydayMessageIsReading(false);
			});
	};
	const totalUnreadCount = Number(unreadCount) + Number(unreadMessagesCount);
	return (
		<>
			<TopNavButton
				badgeContent={totalUnreadCount}
				icon={<NotificationsIcon />}
				onClick={userMenuClick}
				title="Notifications"
			/>
			{user.role[0] !== 'super_admin' ? (
				<Popover
					anchorEl={userMenu}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					className="mt-6"
					onClose={userMenuClose}
					open={Boolean(userMenu)}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					{notifications?.length > 0 || unreadMessagesCount > 0 ? (
						<div className="flex justify-between">
							<div />
							<div className="cursor-pointer delete-notification" onClick={() => readAllNotification()}>
								Clear all
							</div>
						</div>
					) : null}

					<div id="scrollableDiv" style={{ height: 450, width: '460px', overflow: 'auto' }}>
						<InfiniteScroll
							dataLength={notifications.length}
							hasMore={hasMore}
							next={handleLoadMore}
							scrollableTarget="scrollableDiv"
						>
							{unreadMessagesCount > 0 ? (
								<Table>
									<TableBody>
										<TableRow>
											<TableCell
												className="cursor-pointer notification-wrapper"
												onClick={() => {
													History.push('/messaging');
													setUnreadMessagesCount(0);
													setUserMenu(null);
												}}
												style={{ backgroundColor: `#F0F9FE`, width: 448 }}
											>
												<div className="flex" style={{ gap: '5px' }}>
													<div className="">
														<Avatar
															src="assets/images/schoolAdminTopNav/noti-icon.svg"
															style={{ width: '35px', height: '35px' }}
														/>
													</div>
													<div className="flex flex-col">
														<span className="font-bold" style={{ fontSize: '14px', marginBottom: '4px' }}>
															New message received
														</span>
														<span style={{ fontSize: '12px' }}>
															<span className="font-bold">
																You have {unreadMessagesCount} new message
																{unreadMessagesCount > 1 ? 's' : ''}
															</span>
														</span>
													</div>
												</div>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							) : (
								''
							)}
							{loadingNotifications ? (
								<div className="text-center flex flex-col justify-center items-center" style={{ height: '450px' }}>
									<CircularProgress size={30} />
								</div>
							) : notifications?.length ? (
								notifications?.map((notification, key) => {
									const { data } = notification;
									return data?.data?.type?.toLowerCase() === 'checkin_code_request' ? (
										<CheckinCodeNotification
											handleNotificationRead={handleNotificationRead}
											key={key}
											notification={notification}
											userTimeZone={userTimeZone}
										/>
									) : data?.click_action?.toLowerCase() === 'staff_attendance_push_notification' ? (
										<StaffAttendanceNotification
											handleNotificationRead={handleNotificationRead}
											key={key}
											notification={notification}
											userTimeZone={userTimeZone}
										/>
									) : data?.data?.type?.toLowerCase() === 'teacher_announcement' ? (
										<TeacherAnnouncementNotification
											handleAcknowledgeMaydayMessage={handleAcknowledgeMaydayMessage}
											key={key}
											notification={notification}
											userTimeZone={userTimeZone}
										/>
									) : (
										<DefaultNotification
											handleNotificationRead={handleNotificationRead}
											key={key}
											notification={notification}
											userTimeZone={userTimeZone}
										/>
									);
								})
							) : unreadMessagesCount === 0 ? (
								<div className="p-16 text-center">No notifications available</div>
							) : null}
							{fetchingMore ? (
								<Table>
									<TableBody>
										<TableRow>
											<TableCell>
												<div className="flex justify-center">
													<CircularProgress size={35} />
												</div>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							) : null}
						</InfiniteScroll>
					</div>
				</Popover>
			) : null}
		</>
	);
};

export default Notifications;
