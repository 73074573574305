import ModalBase from '@/app/customComponents/modals/ModalBase';
import React, { useState } from 'react';
import { useContext } from 'react';
import RegenerateIcon from '@shared/icons/RegenerateIcon';
import { XMarkIcon } from '@heroicons/react/24/outline';
import GradientButton from '@shared/components/GradientButton';
import { generateStaffCode } from '@/app/services/staff/staff';
import { CircularProgress } from '@material-ui/core';
import { acknowledgeMaydayMessage } from '@/app/services/notifications/notifications';
import { MaydayMessageContext } from '@/app/context/mayday-messages/MaydayMessageContext';
import { getUnreadNotifications } from '@/app/services/notifications/notifications';
// import StyledCheckbox from '@shared/components/StyledCheckbox';
// import { FormControlLabel } from '@material-ui/core';

const ResetLoginCodeModal = ({ setUnreadCount }) => {
	const [initialCodeGenerated, setInitialCodeGenerated] = useState(false);
	const [codeGenerating, setCodeGenerating] = useState(false);
	const [code, setCode] = useState('');
	// const [emailNotify, setEmailNotify] = useState(false);
	const {
		resetLoginCodeModalOpen,
		setResetLoginCodeModalOpen,
		resetLoginCodeModalData,
		setResetLoginCodeModalData,
		setUpdateMaydayMessages,
		setUpdateNotifications,
	} = useContext(MaydayMessageContext);

	const handleGenerateNewCode = () => {
		setCodeGenerating(true);
		generateStaffCode(resetLoginCodeModalData?.data?.data?.staff_id).then((res) => {
			setInitialCodeGenerated(true);
			setCodeGenerating(false);
			setCode(res?.data?.code);
		});
	};

	const handleSubmit = () => {
		try {
			acknowledgeMaydayMessage(resetLoginCodeModalData?.auto_id).then((res) => {
				if (res?.status === 200) {
					setCode('');
					setInitialCodeGenerated(false);
					setResetLoginCodeModalOpen(false);

					// refresh notifications list
					getUnreadNotifications()
						.then((res) => {
							setUnreadCount(res.data.count);
						})
						.catch((err) => {
							console.log(err);
						});

					setResetLoginCodeModalData({});
					setUpdateMaydayMessages(true);
					setUpdateNotifications(true);
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	const handleClose = () => {
		setCode('');
		setInitialCodeGenerated(false);
		setResetLoginCodeModalOpen(false);
		setResetLoginCodeModalData({});
	};

	return (
		<ModalBase hasPadding={false} onClose={handleClose} open={resetLoginCodeModalOpen} showClose={false} showX={false}>
			<div className="p-16 flex flex-col gap-10 min-h-[315px]">
				<div className="flex items-start justify-between gap-10 w-full min-h-[40px]">
					{initialCodeGenerated ? (
						<button
							className="shadow-skeumorphic hover:shadow-skeumorphic-hover active:shadow-skeumorphic-active rounded-8 p-10"
							onClick={handleGenerateNewCode}
							type="button"
						>
							<RegenerateIcon className="size-20" />
						</button>
					) : (
						<span className="text-14 font-semibold" />
					)}
					<button onClick={handleClose} type="button">
						<XMarkIcon className="size-20 text-gray-500" />
					</button>
				</div>
				{initialCodeGenerated ? (
					codeGenerating ? (
						<div className="flex items-center justify-center flex-1">
							<CircularProgress />
						</div>
					) : (
						<div className="py-8 flex flex-col gap-12">
							<div>
								<span className="font-semibold text-14 leading-[20px]">New Code Generated</span>
								<p className="text-14 text-[#535862] leading-[20px]">
									A new code has been generated for{' '}
									<span className="font-bold">{resetLoginCodeModalData?.data?.data?.staff_name}</span>. Please share
									this code with the teacher.
								</p>
							</div>
							<div className="flex items-center justify-center">
								<span className="font-semibold text-64">{code}</span>
							</div>
							<div className="flex flex-col px-72">
								<GradientButton onClick={handleSubmit} type="button">
									<span className="text-14">Done</span>
								</GradientButton>
							</div>
							{/* TODO: future enhancement -- email notify */}
							{/* <div>
						<FormControlLabel
							className="m-0"
							control={<StyledCheckbox checked={emailNotify} onChange={() => setEmailNotify(!emailNotify)} />}
							label={
								<span className="text-14 ml-8 font-bold">
									Notify via email
									<span className=" ml-4 font-normal">(recommended)</span>
								</span>
							}
						/>
					</div> */}
						</div>
					)
				) : (
					<div className="py-8 flex flex-col gap-12 flex-1 justify-between">
						<div>
							<span className="font-semibold text-14 leading-[20px]">New Code Requested</span>
							<p className="text-14 text-[#535862] leading-[20px]">
								<span className="font-bold">{resetLoginCodeModalData?.data?.data?.staff_name}</span> has requested a new
								login code. Click the button below to generate a new code.
							</p>
						</div>
						<div className="flex flex-col px-72">
							{!codeGenerating ? (
								<GradientButton onClick={handleGenerateNewCode} type="button">
									<span className="text-14">Generate Code</span>
								</GradientButton>
							) : (
								<div className="flex items-center justify-center">
									<CircularProgress />
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</ModalBase>
	);
};

export default ResetLoginCodeModal;
