/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Topbar.css';
import * as Actions from '@/app/store/actions';
import { getMaydayMessages } from '@/app/services/notificationtopbar/notificationtopbar';
import { acknowledgeMaydayMessage } from '@/app/services/notifications/notifications';
import { KeyboardArrowDown } from '@material-ui/icons';
import {
	Avatar,
	IconButton,
	Popover,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction,
} from '@material-ui/core';
import { getDayTime } from '@/utils/utils';
import InsetInputButton from '@/app/customComponents/InsetInputButton/InsetInputButton';
import { MaydayMessageContext } from '@/app/context/mayday-messages/MaydayMessageContext';

const Topbar = ({ setUnreadCount }) => {
	const {
		maydayBarVisibility,
		setMaydayBarVisibility,
		maydayMessageData,
		setMaydayMessageData,
		maydayMessageIsReading,
		setMaydayMessageIsReading,
		setUpdateNotifications,
		updateMaydayMessages,
		setUpdateMaydayMessages,
		setResetLoginCodeModalOpen,
		setResetLoginCodeModalData,
	} = useContext(MaydayMessageContext);
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	let interval;

	const [popoverAncherEl, setPopoverAnchorEl] = useState(null);
	const containerRef = useRef(null);

	const handleClick = () => {
		setPopoverAnchorEl(containerRef.current);
		setMaydayBarVisibility(true);
	};

	const handleClose = () => {
		setPopoverAnchorEl(null);
		setMaydayBarVisibility(false);
	};

	useEffect(() => {
		if (!maydayMessageData.notifications.length) {
			handleClose();
		}
	}, [maydayMessageData]);

	const fetchMaydayMessages = () => {
		getMaydayMessages().then((res) => {
			const fetchedNotifications = res.data.data.filter(
				(notification) =>
					!notification.deleted_at && new Date().toISOString().split('T')[0] === notification.created_at.split(' ')[0]
			);
			setMaydayMessageData({
				activeIndex: 0,
				notifications: fetchedNotifications.map((notif) => {
					return {
						...notif,
						created_at: new Date(`${notif.created_at}Z`).toISOString(),
					};
				}),
			});
			if (fetchedNotifications.length) {
				interval = setInterval(() => {
					setMaydayMessageData((prevState) => {
						const n = prevState.notifications.filter(
							(notification) => new Date().toISOString().split('T')[0] === notification.created_at.split('T')[0]
						);
						return {
							notifications: n,
							activeIndex: (prevState.activeIndex + 1) % (n.length > 25 ? 25 : n.length || 1),
						};
					});
				}, 5000);
			}
		});
	};

	useEffect(() => {
		const timeout = setTimeout(fetchMaydayMessages, 500);

		return () => {
			clearInterval(interval);
			clearTimeout(timeout);
		};
	}, [user]);

	useEffect(() => {
		if (updateMaydayMessages) {
			fetchMaydayMessages();
			setUpdateMaydayMessages(false);
		}
	}, [updateMaydayMessages]);

	const handleAcknowledgeMaydayMessage = (notification) => {
		// If it's a staff code reset request, open the modal and return
		if (isStaffCodeResetRequest(notification)) {
			setResetLoginCodeModalData(notification);
			setResetLoginCodeModalOpen(true);
			return;
		}

		// Otherwise, handle regular mayday message acknowledgment
		setMaydayMessageIsReading(true);
		acknowledgeMaydayMessage(notification.auto_id)
			.then((res) => {
				if (res.status === 200) {
					setUnreadCount((prevCount) => prevCount - 1);

					setMaydayMessageData((prevData) => {
						const notifications = prevData.notifications.filter((n) => n.id !== notification.id);
						if (notifications.length === 0) {
							setMaydayBarVisibility(false);
						}
						return {
							...prevData,
							notifications,
							activeIndex: prevData.activeIndex % (notifications.length || 1),
						};
					});

					setUpdateNotifications(true);
				}
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: err?.response?.data?.message || 'Failed to mark notification as read',
						variant: 'error',
					})
				);
			})
			.finally(() => {
				setMaydayMessageIsReading(false);
			});
	};

	const isStaffCodeResetRequest = (notification) => {
		return (
			notification?.data?.title === 'Staff Code Reset Request' && notification?.data?.type === 'teacher_announcement'
		);
	};

	return (
		<div className="relative w-full" ref={containerRef}>
			{maydayMessageData.notifications?.length > 0 ? (
				<div className="flex items-center gap-4">
					<div className="flex flex-1 min-w-0">
						<div className="border rounded-tl-8 rounded-bl-8 border-blue p-0 w-full border-r-0">
							<div className="flex justify-between items-stretch relative">
								<div className="min-w-0 w-full">
									<div className="flex items-center gap-2 justify-between">
										<div className="flex gap-10 flex-1 py-10 pl-5 relative min-w-0 text-red-500">
											<span className="font-bold text-13 text-red-500 shrink-0">
												{`${getDayTime(maydayMessageData.notifications[maydayMessageData.activeIndex]?.created_at)}`}
											</span>
											<span>&mdash;</span>
											<span className="font-bold text-13 text-red-500 line-clamp-2">
												{isStaffCodeResetRequest(maydayMessageData.notifications[maydayMessageData.activeIndex])
													? `${maydayMessageData.notifications[maydayMessageData.activeIndex]?.data?.body} - ${
															maydayMessageData.notifications[maydayMessageData.activeIndex]?.data?.data?.staff_name
														}`
													: `${maydayMessageData.notifications[maydayMessageData.activeIndex]?.data?.data?.body}: ${
															maydayMessageData.notifications[maydayMessageData.activeIndex]?.data?.data?.room?.name
														}`}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<InsetInputButton
							className="shrink-0"
							disabled={maydayMessageIsReading}
							onClick={() => {
								const currentNotification = maydayMessageData.notifications[maydayMessageData.activeIndex];
								handleAcknowledgeMaydayMessage(currentNotification);
							}}
						>
							OK
						</InsetInputButton>
					</div>
					<IconButton className=" size-32 shrink-0" onClick={handleClick} variant="contained">
						<KeyboardArrowDown
							className={`${maydayBarVisibility ? 'scale-y-[-1]' : ''} transition-transform duration-300`}
						/>
					</IconButton>
				</div>
			) : null}

			<Popover
				PaperProps={{
					style: {
						maxHeight: '300px',
						width: '100%',
						maxWidth: '60vw',
						top: '100px',
						borderRadius: '8px',
					},
				}}
				anchorEl={popoverAncherEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				onClose={handleClose}
				open={Boolean(popoverAncherEl)}
				transformOrigin={{
					vertical: -25,
					horizontal: 'center',
				}}
			>
				<List>
					{maydayMessageData.notifications.map((notification, ind) => {
						return (
							<ListItem
								className="hover:bg-pd-blue/5"
								divider={ind !== maydayMessageData.notifications.length - 1}
								key={notification.id}
							>
								<ListItemAvatar>
									<Avatar src={isStaffCodeResetRequest(notification) ? null : notification?.data?.data?.room?.thumb} />
								</ListItemAvatar>
								<ListItemText
									primary={
										<span className="text-13 font-bold text-red-600">
											{isStaffCodeResetRequest(notification)
												? notification?.data?.data?.staff_name
												: notification?.data?.data?.room?.name}
										</span>
									}
									secondary={
										<span className="text-13 text-red-600 font-bold">
											{notification?.data?.body || notification?.data?.title || notification?.data?.data?.body}
										</span>
									}
								/>
								<ListItemSecondaryAction>
									<div className="flex items-center gap-10">
										<span className="text-12 text-gray-600">{getDayTime(notification?.created_at)}</span>
										<IconButton edge="end" onClick={() => handleAcknowledgeMaydayMessage(notification)} size="small">
											<img alt="close" src="assets/images/cross-icon.svg" />
										</IconButton>
									</div>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
				</List>
			</Popover>
		</div>
	);
};

export default Topbar;
