import FuseUtils from '@/@fuse/utils/FuseUtils';
import axios from '@shared/axios-shared';
import { getSchoolProfile } from '@/app/services/settings/settings';
import history from '@/@history';
import secureLocalStorage from 'react-secure-storage';

class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(err) => {
				return new Promise(() => {
					if (err?.response?.status === 401 && err?.config && !err.config?.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						this.setSession(null);
						localStorage.clear();
						this.emit('onAutoLogout', err?.response?.data?.message);
						history.push('/login');
					}
					throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		const accessToken = this.getAccessToken();

		if (!accessToken) {
			this.emit('onNoAccessToken');

			return;
		}

		if (this.isAuthTokenValid(accessToken)) {
			this.setSession(accessToken);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'Session expired. Please Login');
		}
	};

	createUser = (data) => {
		return new Promise((resolve, reject) => {
			axios.post('/api/auth/register', data).then((response) => {
				if (response.data.user) {
					this.setSession(response.data.access_token);
					resolve(response.data.user);
				} else {
					reject(response.data.error);
				}
			});
		});
	};

	signInWithEmailAndPassword = (username, password) => {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/v2/login', {
					username,
					password,
					user_role: 'admin',
					device_name: 'web',
				})
				.then((response) => {
					if (response?.data?.access_token) {
						this.setSession(response.data.access_token);
						this.getProfile()
							.then((res) => {
								if (!res.data.verified_at) {
									history.push({
										pathname: '/reset-password',
										state: {
											username: res.data.email,
										},
									});
									this.emit('onAutoLogout', 'Please Update your password');
								} else {
									getSchoolProfile().then((school) => {
										const user = {
											data: {
												email: res.data.email,
												displayName: `${res.data.first_name} ${res.data.last_name}`,
												photoURL: res.data.photo,
												school,
												...res.data,
											},
											permissions: response?.data?.user_permission,
											role: response?.data?.user_roles,
										};
										user.data.selected_school = school.id;
										this.setSession(response.data.access_token, user);
										resolve(user);
									});
								}
							})
							.catch((err) => {
								this.emit('onAutoLogout');
								this.setSession(null);
								reject(err);
							});
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	signInWithToken = (access_token, company_id = null, school_id = null, allow_unverified = false) => {
		let token = access_token || this.getAccessToken();
		return new Promise((resolve, reject) => {
			if (!token) {
				this.logout();
				reject(new Error('No token provided.'));
				return;
			}
			let user = JSON.parse(secureLocalStorage.getItem('user'));
			if (user) {
				user.data.selected_school = school_id || null;
				user.data.selected_company = company_id || null;
			}
			this.setSession(token, user);
			this.getProfile()
				.then((res) => {
					if (!['super_admin', 'school_admin', 'product_owner'].includes(res.data.role)) {
						this.emit('onAutoLogout');
						this.setSession(null);
						reject(new Error('Cannot access this application.'));
						return;
					}
					if (!res.data.verified_at && !allow_unverified) {
						history.push({
							pathname: '/reset-password',
							state: {
								username: res.data.email,
							},
						});
						this.emit('onAutoLogout', 'Please Update your password');
						reject(new Error('Please Update your password'));
						return;
					}
					const user = {
						data: {
							email: res.data.email,
							displayName: `${res.data.first_name} ${res.data.last_name}`,
							photoURL: res.data.photo,
							...res.data,
							selected_company: company_id || res.data?.school.company_id || res.data?.school.id || null,
							selected_school: school_id || (res.data?.school.company_id ? res.data?.school.id : null) || null,
						},
						role: [res.data.role],
					};
					this.setSession(token, user);
					resolve(user);
				})
				.catch(() => {
					this.emit('onAutoLogout');
					this.setSession(null);
				});
		});
	};

	signInWithSingleUseLogin = (token, hash, company_id = null, school_id = null) => {
		return new Promise((resolve, reject) => {
			axios
				.post('api/v2/login/token', { token, hash })
				.then((response) => {
					if (!response.data.access_token) {
						reject(response.data.message || 'Failed to login with token.');
						return;
					}

					this.signInWithToken(response.data.access_token, company_id, school_id, true)
						.then((user) => resolve(user))
						.catch((error) => reject(error));
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	updateUserData = (user) => {
		return axios.post('/api/auth/user/update', {
			user,
		});
	};

	setSession = (accessToken, user) => {
		if (accessToken) {
			secureLocalStorage.setItem('jwt_access_token', accessToken);
			if (user) {
				secureLocalStorage.setItem('user', JSON.stringify(user));
				this.setSchool(user.data?.selected_school || null);
				this.setCompany(user.data?.selected_company || null);
			}
			axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
		} else {
			localStorage.clear();
			this.setSchool(null);
			this.setCompany(null);
			delete axios.defaults.headers.common.Authorization;
		}
	};

	clearUser = () => {
		secureLocalStorage.removeItem('user');
		this.setSchool(null);
	};

	setSchool = (school_id) => {
		if (school_id) {
			secureLocalStorage.setItem('selected_school', school_id);
			axios.defaults.headers.common['X-School-Id'] = school_id;
		} else {
			secureLocalStorage.removeItem('selected_school');
			delete axios.defaults.headers.common['X-School-Id'];
		}
	};

	setCompany = (company_id) => {
		if (company_id) {
			secureLocalStorage.setItem('selected_company', company_id);
			axios.defaults.headers.common['X-Company-Id'] = company_id;
		} else {
			secureLocalStorage.removeItem('selected_company');
			delete axios.defaults.headers.common['X-Company-Id'];
		}
	};

	logout = async (redirect = true) => {
		if (this.getAccessToken()) {
			try {
				await axios.post('api/v1/logout');
			} catch (err) {
				console.error(err);
			}
		}
		if (redirect) {
			history.push('/login');
		}
		this.setSession(null);
		this.setSchool(null);
	};

	isAuthTokenValid = (accessToken) => {
		if (!accessToken) {
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return secureLocalStorage.getItem('jwt_access_token');
	};

	getProfile = (isSuperSchoolAdmin = false) => {
		if (isSuperSchoolAdmin) {
			return axios.get('/api/v2/profile', {
				headers: { Authorization: `Bearer ${secureLocalStorage.getItem('superadmin_token')}` },
			});
		}
		return axios.get('api/v2/profile');
	};

	setViewAs = (otp, school_id) => {
		if (otp) {
			const tempUser = JSON.parse(secureLocalStorage.getItem('user'));
			tempUser.role[0] = 'super_school_admin';
			if (school_id) {
				tempUser.data.selected_school = school_id;
			}
			secureLocalStorage.setItem('user', JSON.stringify(tempUser));
		} else {
			delete axios.defaults.headers.common.Authorization;
		}
		this.setSchool(school_id);
	};
}

const instance = new JwtService();

export default instance;
