import React from 'react';

const DialogScrollContainer = ({ id, children }) => {
	return (
		<div
			className="h-[320px] max-h-[280px] border-2 border-[#D3D3D3] rounded-[10px] overflow-y-auto w-[90%] mx-auto"
			id={id}
		>
			{children}
		</div>
	);
};

export default DialogScrollContainer;
