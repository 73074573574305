import * as React from "react";
const SuccessCircleCheckIcon = (props) => (
  <svg
    fill="none"
    viewBox="0 0 74 74"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#10A957" height="100%" rx={37} width="100%" />
    <path
      d="M37 14.5c-12.398 0-22.5 10.102-22.5 22.5S24.602 59.5 37 59.5 59.5 49.398 59.5 37 49.398 14.5 37 14.5Zm10.755 17.325L34.997 44.583a1.686 1.686 0 0 1-2.385 0l-6.367-6.368a1.698 1.698 0 0 1 0-2.385 1.698 1.698 0 0 1 2.385 0l5.175 5.175L45.37 29.44a1.698 1.698 0 0 1 2.385 0c.652.652.652 1.71 0 2.385Z"
      fill="#fff"
    />
  </svg>
);
export default SuccessCircleCheckIcon;
